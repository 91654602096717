<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.config") }}
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        small
        outlined
        class="mr-1"
        @click="$refs.inputUploadFile.click()"
      >
        <v-icon>mdi-upload</v-icon>
        {{ $t("labels.upload") }}
      </v-btn>
      <input
        type="file"
        ref="inputUploadFile"
        accept=".xlsx"
        @change="onInputFileChange"
        class="d-none"
      />

      <v-btn
        color="success"
        small
        outlined
        class="mr-1"
        @click="create"
        :disabled="items.length < 1"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
      <v-btn color="error" small outlined @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        fixed-header
        height="calc(100vh - 245px)"
        class="table-padding-2-no-top"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th>{{ $t("labels.barcode") }}</th>
              <th>{{ $t("labels.quantity") }}</th>
              <th>Ghi chú</th>
              <!-- <th>Tên hàng</th>
                        <th>Đặc tả</th>
                        <th>Size</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, key) in items" :key="`re_${key}`">
              <td>{{ item.customer_goods_barcode }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.note }}</td>
              <!-- <td>{{ item.name }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.size }}</td> -->
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { read, utils } from "xlsx";

export default {
  name: "ReservedQuantity",
  components: {},
  props: {},
  data: () => ({
    items: [],
    isLoading: false,
    hasChange: false,
  }),
  watch: {},
  mounted() {},
  methods: {
    async downloadExcel() {},

    async onInputFileChange(e) {
      const mappingFields = {
        Barcode: "customer_goods_barcode",
        "Số lượng": "quantity",
        "Ghi chú": "note",
        /* 'Tên hàng': 'name',
                'Đặc tả': 'description',
                'Size': 'size', */
      };
      const files = e.target.files,
        f = files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        let sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const values = utils.sheet_to_json(worksheet);
        const items = [...values].map((v) => {
          const item = {};
          Object.keys(v).forEach((i) => {
            item[mappingFields[i]] = `${v[i]}`.trim();
          });
          return item;
        });
        this.validateData(items);
      };
      await reader.readAsArrayBuffer(f);
    },

    validateData(items) {
      if (!items || items.length === 0) {
        this.$vToastify.error(this.$t("messages.file_is_empty"));
        return false;
      }

      if (items.length > 1000) {
        this.$vToastify.error(this.$t("messages.update_max_1000_rows"));
        return false;
      }

      const emptyQuantity = items
        .filter(
          (item) =>
            !item.customer_goods_barcode ||
            !item.quantity ||
            item.quantity == "0"
        )
        .map((item) => item.customer_goods_barcode);
      if (emptyQuantity && emptyQuantity.length > 0) {
        this.$vToastify.error(
          this.$t("messages.data_goods_missing", {
            barcodes: emptyQuantity.join("; "),
          })
        );
        return false;
      }

      const duplicateBarcodes = [];
      const noDuplicateBarcodes = [];
      items.forEach((i) => {
        const nd = [...noDuplicateBarcodes].find(
          (n) => n == i.customer_goods_barcode
        );
        if (!nd) {
          noDuplicateBarcodes.push(i.customer_goods_barcode);
        } else {
          duplicateBarcodes.push(i.customer_goods_barcode);
        }
      });
      if (duplicateBarcodes.length > 0) {
        this.$vToastify.error(
          this.$t("messages.duplicate_barcodes", {
            barcodes: duplicateBarcodes.join("; "),
          })
        );
        return false;
      }

      this.items = [...items];
    },

    closeDialog() {
      if (this.hasChange) {
        this.$emit("refreshData", true);
      }
      this.$emit("cancel", true);
    },

    async create() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/create-transfer-quantity-config-by-list", {
          items: this.items,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.hasChange = true;
        this.items = [];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
